// We need to include ie11 polyfills used by webpack dynamic import
// because webpack generated code does not go through babel
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'intersection-observer';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Fuse from 'fuse.js';

/**
 * Bugglyfill mobile safari navbar-hacks
 */
const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
require('viewport-units-buggyfill').init({
    hacks,
});

// App main
const main = async () => {
    // Async load LazySizes and it's plugins
    const LazySizes = await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/respimg/ls.respimg.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/parent-fit/ls.parent-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/object-fit/ls.object-fit.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/blur-up/ls.blur-up.js'
    );
    await import(
        /* webpackChunkName: "LazySizes" */ 'lazysizes/plugins/attrchange/ls.attrchange'
    );
    // fix issue when image is already in viewport and content is not loaded yet
    document.addEventListener('DOMContentLoaded', function () {
        LazySizes.init();
    });
    window.Fuse = Fuse;
    Alpine.plugin(collapse);
    window.Alpine = Alpine;
    Alpine.start();
};

// Execute async function
main().then(() => {
    const banner = document.getElementById('banner');
    if (banner && banner.classList.contains('bannerIsActive')) {
        import(/* webpackChunkName: "banner" */ './modules/banner').then(
            ({ initBanner }) => {
                initBanner();
            },
        );
    }

    // gLightBox
    const gLightBox = document.getElementsByClassName('glightbox');
    if (gLightBox.length) {
        import(/* webpackChunkName: "gLightBox" */ './modules/glightbox');
    }
    /**
     * Headroom
     */
    const headroom = document.querySelector('.js-nav');
    if (headroom) {
        import(/* webpackChunkName: "headroom" */ './modules/headroom')
            .then(module => module.default.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // load sliderjs-carousel
    if (document.getElementsByClassName('js-carousel').length) {
        import(/* webpackChunkName: "glide" */ './modules/glide.js')
            .then(glide => glide.carousel.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // load sliderjs-review
    if (document.getElementsByClassName('js-review').length) {
        import(/* webpackChunkName: "glide" */ './modules/glide.js')
            .then(glide => glide.review.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }

    // load tippy.js
    if (document.querySelector('[data-tippy-content]')) {
        import(/* webpackChunkName: "tippy" */ './modules/tippy.js')
            .then(module => module.tippyJs.init())
            .catch(e => console.error(`${e.name} : ${e.message}`));
    }
});

// accept HMR in dev
if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept();
    }
    // eslint-disable-next-line no-unused-expressions
    // import(/* webpackChunkName: "debug" */ '../vue/debug');
}
